.truckRunsInfo {
  &_row, &_header {}

  &_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #F7F7F7;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    padding: 1em 1.5em;
    margin: 1em 1.5em;

    &_infoCellItems {
      display: flex;
      flex-wrap: wrap; }

    &_action {
      display: flex;
      flex-direction: row;
      justify-content: flex-end; } }


  &_header {
    padding: 1.5em 1.7em 0;
    border-top: 1px solid #E2E8F0;
    background: #FFFFFF;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px; } }
