.forwarderAttaches {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; }

  &__content {
    margin: 1.5em; }

  div.InfoCard {
    h1 {
      margin-bottom: 1em !important;
      font-size: 18px; } }

  .MuiInputBase-root .MuiInputAdornment-positionEnd button {
    margin-right: 0; }

  &__attachTitle {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.5px;
    color: #111111;
    margin-bottom: 1em; }

  &__Item {
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    background: #FAFAFA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    overflow: none;
    margin: 4px 0;

    &__status_active {
      border-left: 6px solid #0A5DFF; }

    &__status_archival {
      border-left: 6px solid #B2B2B2; }

    &__status_planned {
      border-left: 6px solid #6DCD45; }

    &__info {
      width: 100%;
      margin: 0 24px;
      letter-spacing: -0.02em;

      &_period {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0F172A; }

      &_address {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #111111; }

      &_dot {
        font-size: 20px;
        color: #B2B2B2;
        margin: 0 12px; } }

    &__map {
      min-width: fit-content;
      display: flex;
      align-items: center;
      width: 422px;
      height: 72px; } } }
