.registryCarriers_drivers {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &__body {
    flex-grow: 3;
    overflow-x: hidden;
    overflow-y: auto;

    &_content {
      margin: 1.5em; } } }
