.carrierShipping {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__body {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    padding-bottom: 1px;

    &_items {
      margin: 1.5em;

      .tripsCard {
        margin-bottom: 1em; }

      .tripsCard:last-child,
      .tripsCard:only-child {
        margin: 0; } } } }
