.notification {
  padding: 8px 16px;
  background: #FAFAFA;
  border-radius: 4px;
  display: flex;
  align-items: center;
  line-height: 17px;
  font-size: 14px;

  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;

    &.success {
      color: #6DCD45; }

    &.info {
      color: #0A5DFF; }

    &.warning {
      color: #FFA800; }

    &.error {
      color: #e60b0b; } }

  .message {
    display: inline-block;
    flex-grow: 3;
    margin: 0 11px; }

  .MuiButtonBase-root {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background-color: #B2B2B2;
    color: #FFF; } }

