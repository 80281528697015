.dispatcherBids {
  &__truckRunsList {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    &_content {
      display: flex;
      flex-grow: 3;
      overflow-x: hidden;
      overflow-y: auto;

      &_items {
        flex-grow: 3;
        margin: 1.5em; } } } }
