body {
  background-color: #F7F7F7;
  padding: 0;
  margin: 0;
  font-family: Inter; }

.mainLayout {
  display: flex;

  &__sidebar {}

  &__content {
    background-color: #F8FAFC;
    border-left: 1px solid #E2E8F0;
    width: 100%;
    padding: 0; } }


