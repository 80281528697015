.dispatcherBid {
  display: flex;
  flex-direction: column;
  height: 100vh;

  &__btn {
    border-radius: 4px !important;
    text-transform: none !important; }

  .infoIconText {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #B2B2B2;
    margin: 1em; }

  &_body {
    flex-grow: 3;
    overflow-x: hidden;
    overflow-y: scroll;

    &_basicInfo,
    &_freeSpace {
      margin: 1.5em; }


    &_basicInfo {
      &_addressCard {
        display: flex;
        justify-content: space-between;
        border: 1px solid #E2E8F0;
        border-radius: 4px;
        margin: 1em 0;
        background: #ffffff;

        &_fields {
          width: 70%;
          margin: 1.5em;
          margin-bottom: 2em; }

        &_map {
          width: 30%;
          display: flex;
          border-left: 1px solid #E2E8F0;
          background: #F7F7F7;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          color: #B2B2B2; } } }

    &_freeSpace {
      &_slotItems {
        &_item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end; } } } } }

