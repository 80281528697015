.registryRoute {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__content {
    flex-grow: 3;
    overflow-x: hidden;
    overflow-y: scroll;

    padding: 1.5em; } }
