.dispatcherResponses {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;
  background: #FAFAFA;


  &__body {
    display: flex;
    flex-direction: column;
    width: 60%;
    border-right: 1px solid #E2E8F0;

    // .navigatePanel__tabs
    //   width: 500px !important

    &_content {
      flex-grow: 3;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &_items {
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        margin: 1.5em; }

      &_none {
        display: flex;
        width: 100%;
        height: 100%; } } }

  &__desc {
    width: 40%;
    height: 100%;

    display: flex; } }
