.modalMap {
  min-width: 500px;
  &__row {
    pading: 0;
    text-align: left;

    &_map {
      width: 100%;
      height: 382px; } }

  &__btn {
    border-radius: 4px !important;
    text-transform: none !important; } }


