.modal_act_step_1 {
  tr {
    td {
      padding: 0.5em 0; } } }

.modal_signing_box {
  .MuiAlert-standardError {
    margin-top: 1.5em; } }

.modal_sign_alert_box {
  margin-bottom: 1em;

  .MuiAlert-root {
    margin-top: 1.5em; } }
