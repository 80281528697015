.forwarderAllLoads {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  &__content {
    flex-grow: 3;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &__items {
      margin: 1.5em; } } }
