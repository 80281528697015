.carrierBid {
  display: flex;
  flex-direction: row;
  height: 100vh;

  &_map {
    width: 50%;
    height: 100%;
    overflow: hidden; }

  &_content {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;

    &_info {
      height: 100%;

      overflow-y: scroll;
      overflow-x: hidden; }

    &_infoCard {
      margin: 1em 1.5em !important;
      width: auto !important; }

    .freeSpaceCard {
      width: 100%;
      border-color: #0A5DFF;
      border-radius: 8px;

      &_row {
        width: 100%;
        display: flex;
        lex-wrap: wrap;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin: 0 -5px;

        &_item {
          margin: 5px; } } }

    button {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        min-width: 20px;
        border-radius: 4px;
        text-decoration: none !important; }

    &_buttonResponse {
        text-transform: none !important;
        margin-right: 8px !important;
        background: #0365F4 !important; }

    &_buttonBack {
      margin-left: -10px !important; } } }
