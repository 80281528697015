.chipStatus {
  display: flex;
  align-items: center;
  width: fit-content;
  text-align: center;
  border: 1px solid #EBEBEB;
  background: #EBEBEB;
  border-radius: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #111111;
  padding: 4px 14px;
  word-wrap: break-word;

  &__outlined {
    background: none !important;
    color: #111111 !important; }

  &__color {
    &_blue, &_red {
      color: #ffffff; }

    &_lightGrey {
      background: #F7F7F7;
      color: #B2B2B2; }

    &_blue {
      background: #0A5DFF;
      border-color: #0A5DFF; }

    &_red {
      background: #ee6a5f;
      border-color: #ee6a5f;
      color: #ffffff; }

    &_yellow {
      background-color: #ffa800;
      border-color: #ffa800;
      color: #ffffff; }

    &_green {
      background-color: #6dcd45;
      border-color: #6dcd45;
      color: #ffffff; }

    &_yellowBorder {
      background-color: #FFFFFF;
      border-color: #ffa800; } } }
