.bidsRouteComponent {
    //font-weight: 600
    font-size: 14px;
    line-height: 17px;

    &_content {
      padding-left: 15px; }

    &_start, &_end {
      position: relative;
      width: 100%; }

    &_start {
      //border-left: 2px solid #0365F4
      padding-bottom: 16px; }

    &_point {
      position: absolute;
      display: block;
      top: 2px;
      left: 0;
      border-radius: 50%;
      border: 3px solid #ffffff;
      height: 8px;
      width: 8px;
      background: #0365F4;
      z-index: 1; }

    &_line {
      position: absolute;
      top: 10px;
      left: 5.5px;
      width: 2px;
      height: 100%;
      background: #0365F4; } }
