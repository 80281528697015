.systemSettings {
  height: 100vh;
  overflow: hidden;

  &__body {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;

    &_content {
      margin: 1.5em;

      .infoCard {
        margin-top: 1em; } } } }
