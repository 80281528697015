.carrierShippingInfo {
  display: flex;
  flex-direction: row;
  height: 100vh;

  &_map {
    width: 50%;
    height: 100%;
    overflow: hidden; }

  &_content {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;

    &_info {
      height: 100%;

      overflow-y: scroll;
      overflow-x: hidden; }

    &_infoCard {
      margin: 1em 1.5em !important;
      width: auto !important; } } }
