.carrierHome {
  height: 100vh;
  width: 100%;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;

    &_trips,
    &_notify {
      margin: 1.5em; }

    &_trips {
      height: 100%;
      flex-grow: 3; }

    &_notify {
      flex-grow: 1;
      margin-top: 0; } } }
