.bidsComponentsFreeSpaceItem {
  position: relative;
  border: 1px solid #CBD5E1;
  height: fit-content;
  border-radius: 2px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  background: #FAFAFA;

  &_selected {
    border-color: #0365F4; }

  p {
    margin: 4px; }

  &_date {
    border-bottom: 1px solid #CBD5E1; }

  &_date, &_seats, &_reserve {
    width: 100%;
    height: 24px; }

  &_seats {
    &_border {
      border-bottom: 1px solid #CBD5E1; } }

  &_reserve {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_control, &_count {
      margin: 4px; }

    &_control {
      button {
        padding: 0;
        background: #EBEBEB;
        max-width: 20px;
        max-height: 20px; } } }

  // &_date
  //   position: absolute
  //   border-top: 1px solid #CBD5E1
  //   background: #E2E8F0
 }  //   color: #94A3B8

.bidsComponentsFreeSpaceItemBig {
  width: 100px; }

.bidsComponentsFreeSpaceItemSmall {
  width: 52px; }
