.authLayout {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: #F7F7F7;

  &__logo {
    margin: 5em 0;
    width: 100%;
    text-align: center; }

  &__content {
    margin-bottom: 2em;
    height: 100%;
    display: flex;
    flex-grow: 3; } }
