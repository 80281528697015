$BORDER: 1px solid #ebebeb;
$MARGIN_CARD_CONTENT: 1.5em;

.dispatcherTrips {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;

  &__content::-webkit-scrollbar {
    display: none; }

  &__content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &__items {
      margin: 1.5em; } }

  &__card {
    display: flex;
    justify-content: space-between;
    border: $BORDER;
    border-radius: 4px;
    background: #ffffff;
    margin: 0;
    min-width: fit-content;
    margin-bottom: 1em;
    &:last-child,
    &:only-child {
      margin: 0; }

    &_body {
      display: flex;
      flex-direction: column;
      background: #ffffff;
      flex-grow: 2;
      overflow-x: hidden;

      &_title {
        margin: 1em $MARGIN_CARD_CONTENT 1em $MARGIN_CARD_CONTENT;

        div.bidsRouteComponent {
          color: #B2B2B2;
          font-size: 13px;
          font-weight: 400;

          div.bidsRouteComponent_start {
            padding-bottom: 5px !important; }

          span.bidsRouteComponent_point,
          div.bidsRouteComponent_line {
            background: #B2B2B2; } } }


      &_info {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 0 $MARGIN_CARD_CONTENT 1em $MARGIN_CARD_CONTENT;
        min-width: fit-content; } }

    &_map {
      flex-grow: 1;
      width: 30%;
      max-width: 468px; } } }
