.collapseList {
  &_button {
    display: flex;
    justify-content: center;
    border-top: 2px dotted #EBEBEB;
    color: #3788d8;
    margin-top: 0.5em; }

  &_button:hover {
    cursor: pointer;
    background: #eeeeee; }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &_preview {}

    &_collapse,
    &_preview {
      &_list {
        display: flex;
        flex-direction: column;
        gap: 0.5em;

        &_item {} } } } }
