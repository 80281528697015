
$BORDER: 1px solid #ebebeb;
$MARGIN_CARD_CONTENT: 1.5em;

.tripsCard {
  display: flex;
  justify-content: space-between;
  border: $BORDER;
  border-radius: 4px;
  background: #ffffff;
  margin: 0;
  min-width: fit-content;

  &_map {
    display: flex;
    justify-items: center;
    align-items: center;
    width: 30%;
    min-width: 30%;
    border-left: $BORDER;
    margin: 0;
    overflow: hidden; }

  &_content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;


    &_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &_map {
        width: 30%;
        height: 100%; }

      &_body {
        display: flex;
        flex-direction: column;
        background: #ffffff;
        flex-grow: 2;
        overflow-x: hidden;

        &_panel {
          margin: $MARGIN_CARD_CONTENT;
          margin-bottom: 0;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &_title {
            min-width: 10em; }

          &_control {
            display: flex;
            justify-content: flex-end;
            min-width: fit-content; } }

        &_info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin: 1em $MARGIN_CARD_CONTENT $MARGIN_CARD_CONTENT;
          min-width: fit-content;

          &_route {
            min-width: 40%;
            max-width: 40%;
            margin-right: 2em; }

          &_description {
            display: flex;
            justify-content: flex-start;
            min-width: fit-content;
            overflow-x: hidden; } } } }

    &_trips {
      margin: 0; } }

  .tripsCard_collapseBox {
    .truckRunsInfo {}
    .truckRunsInfo_row {
      background: #FFFFFF;
      margin: 0.5em 1em; } } }


.tripsItem {
  &_row {
    display: flex;
    margin: $MARGIN_CARD_CONTENT 0;
    overflow-x: hidden;

    &_item {} } }
