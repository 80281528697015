.TextField {
  &_field {
    height: 40px;
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    color: #111111 !important;
    background: #F7F7F7;

    &_error {
      border-color: red !important; }

    input {
      margin: 8px 12px; }

    textarea {
      margin: 8px 12px; } } }
