.dispatcherHome {
  height: 100vh;
  overflow: hidden;

  &__row {
    margin: 0 0 1em; }

  &__row:only-child,
  &__row:last-child {
    margin: 0; }

  &__infoCard {
    flex-grow: 3;


    .infoCell {
      gap: 0;

      &_value {
        font-weight: 500; } } }

  &__body {
    display: flex;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto; } }
