.bidsFilters {}

.bidFiltersModal {
  &_header {
    font-weight: 600;
    font-size: 24px;
    line-height: 34px;
    margin: 0; }

  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 40px;
    min-width: 32px;
    border-radius: 4px;
    text-decoration: none !important;
    text-transform: none !important;
    color: #475569; }

  &_saveButton {
    background: #0365F4 !important;
    color: #ffffff !important; } }
