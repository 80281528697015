.forwarderHome {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;

  &__content {
    height: 100vh;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }

  &__AttachItem {
    border: 1px solid #EBEBEB;
    border-radius: 4px;
    background: #FAFAFA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    overflow: none;
    border-left: 6px solid #0A5DFF;
    margin: 4px 0;

    &__info {
      width: 100%;
      margin: 0 24px;
      letter-spacing: -0.02em;

      &_period {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0F172A; }

      &_address {
        font-weight: 400;
        font-size: 13px;
        line-height: 16px;
        color: #111111; }

      &_dot {
        font-size: 20px;
        color: #B2B2B2;
        margin: 0 12px; } } } }
