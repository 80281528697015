.dispatcherAct {
  display: flex;
  flex-direction: row;
  height: 100vh;

  &_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &_body {
      height: 100%;

      overflow-y: scroll;
      overflow-x: hidden;
      padding: 1.5em;

      &_truckRunsItems {
        div.truckRun_selected {
          div.infoCell_value {
            color: #624CE0; } }

        div.truckRunItem {
          &_disabled {
            filter: grayscale(1);
            cursor: default !important;

            div.infoCell {
              &_value {
                color: #94A3B8 !important; } } } } } } } }
