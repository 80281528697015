.collapseBox {
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &_title {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.02em; }

    &_line {
      flex-grow: 3;
      border-bottom: 1px solid #EBEBEB;
      margin: 0 1em; }

    &_control {} } }
