.InfoCard {
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 1px solid #E2E8F0;
  border-radius: 4px;
  margin: 6px 0;
  // overflow: hidden

  &_body {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px;

    &_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      align-items: center;

      &_title {
        font-weight: 600;
        font-size: 18px;
        line-height: 27px;
        color: #111111;
        margin: 0;
        padding: 0; } }

    &_items {
      display: inline;

      &_infoItem:last-child,
      &_infoItem:only-child {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0; }

      &_infoItem {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #E2E8F0;
        margin-bottom: 14px;
        padding-bottom: 14px;
        font-weight: 500;
        font-size: 13px;
        align-items: center;

        &_label {
          width: 30%;
          line-height: 17px;
          color: #B2B2B2; }

        &_value {
          width: 70%;
          line-height: 17px; } } } } }
