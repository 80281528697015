
.trips {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;

  &__content::-webkit-scrollbar {
    display: none; }

  &__content {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &__items {
      margin: 1.5em;

      .tripsCard {
        margin-bottom: 1em; }

      .tripsCard:last-child,
      .tripsCard:only-child {
        margin: 0; } } } }
