.carrierBids {
  &_viewTable {
    margin: 1.5em;

    &_freeSpaceRow {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      flex-grow: 1;

      &_item:only-child,
      &_item:last-child, {
        margin: 0; }

      &_item {
        margin-right: 5px; }

      &_count {
        display: grid;
        width: 52px;
        border: 1px solid #CBD5E1;
        border-radius: 2px;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        align-items: center;
        background: #FAFAFA; } } } }

