.dispatcherRegisters {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__content {
    flex-grow: 3;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none; }

  &__body {
    padding: 1em; } }
