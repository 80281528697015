.carrierCalendar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  &_content {
    flex-grow: 3;

    overflow-y: hidden;
    overflow-x: hidden;
    // padding: 10px

    // Переопределить некоторые стили календаря
    // НЕУДАЛЯТЬ
    .fc, .fc-theme-standard {
      width: 100%;
      background: #FFFFFF;
      font-family: Inter !important;

      td, th {
        border-color: #E2E8F0; }

      .fc-scrollgrid-sync-table {
        td.fc-day:hover {
          background: #0a5cff0a;
          cursor: pointer; }

        td.fc-day {
          padding: 4px;
          .fc-daygrid-day-top {
            justify-content: center;
            font-size: 13px;
            font-weight: 500; } }

        .fc-daygrid-day-events {
 }          // padding: 4px

        td.fc-day-other {
          background: #FAFAFA;
          background-clip: padding-box; }

        td.fc-day-today {
          background: none;

          .fc-daygrid-day-top {
            a.fc-daygrid-day-number {
              background: #0A5DFF;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              color: #FFFFFF;
              text-align: center; } } } }

      .fc-view-harness,
      .fc-view-harness-active {

        .fc-popover,
        .fc-more-popover {
          z-index: 1200; }

        .fc-col-header {
          th {
            color: #B2B2B2;
            font-size: 13px;
            font-weight: 600;
            text-transform: uppercase; } }

        .fc-dayGridMonth-view,
        .fc-view,
        .fc-daygrid {

          .fc-scrollgrid,
          .fc-scrollgrid-liquid {
            border: none; } } }

      .fc-more-popover {
        .fc-popover-body {
          max-height: 200px;
          overflow-y: auto;
          overflow-x: hidden; } } } } }


