.sidebar {
  &__icon {
    svg {
      width: 20px;
      height: 20px;
      color: #B2B2B2;
      fill: #B2B2B2; } }
  &__iconActive {
    svg {
      color: #ffffff !important;
      fill: #ffffff!important; } }

  &__brand {
    & img {
      display: inline-block;
      margin-right: 0.8em;
      height: 1.8em;
      width: auto;
      vertical-align: middle; }

    & svg {
      display: inline-block;
      height: 1.1em;
      width: auto;
      vertical-align: middle; } }

  &__groupItems {
    &_item:only-child,
    &_item:last-child {
      hr {
        display: none; } }

    &_item {
      &_divider {
        margin: 0 1.5em !important; } } } }
