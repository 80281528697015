.progressBar {
  width: 100%;
  height: fit-content;
  position: relative;

  &_info {
    position: absolute;
    z-index: 10;
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #ffffff;
    padding: 0 1em;
    white-space: nowrap;
    overflow: hidden; }

  &_bar {
    z-index: 1; } }
