.dispatcherBids {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100vh;

  &__btn {
    border-radius: 4px !important;
    text-transform: none !important; }

  &_bids {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &_content {
      flex-grow: 3;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;

      &_items {
        margin: 1.5em;

        div.selectBid {
          border: 1px solid #0A5DFF; } } } }

  &_truckRunsPanel {
    height: 100%;
    width: 50%;
    flex-grow: 1;
    border-left: 1px solid #EBEBEB;

    &_notSelectedBid {
      display: flex;
      flex-grow: 3;
      justify-content: center;
      align-items: center;
      height: 100%;

      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      letter-spacing: -0.02em; }

    &_content {
      display: flex;
      flex-direction: column;
      height: 100%;

      &_tabs {
        background: #ffffff;
        border-bottom: 1px solid #EBEBEB;
        max-width: 50vw; }

      &_progress {
        display: flex;
        justify-content: space-between;
        margin: 1.5em;
        align-items: center;

        &_bar {
          width: 70%; }

        &_control {
          margin-left: 1em;
          width: 30%; } }

      &_items {
        flex-grow: 3;
        height: 100%;
        overflow-x: hidden;
        overflow-y: scroll;

        &_collapseBox {
          margin: 0 1.5em; }

        &_item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          margin: 0.5em 0;

          &_info {
            // width: 90%
            flex-grow: 3;
            height: 100%;

            &_card {
              height: 100%;
              margin: 0;

              &_descriptions {
                display: flex;
                flex-direction: row; } } }

          &_control {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 0.5em;
            // flex-grow: 1

            button:last-child {
              margin: 0; }

            button:only-child {
              margin: 0;
              min-height: 100% !important; }

            button {
              margin-bottom: 0.5em; } } } } } } }


