.nearestTrips {
  display: flex;
  flex-direction: row;
  height: 100%;

  border: 1px solid #E2E8F0;
  border-radius: 4px;
  overflow: hidden;

  background: #ffffff;

  &__body {
    display: flex;
    flex-direction: column;
    width: 70%;
    border-right: 1px solid #E2E8F0;

    &_head {
      margin: 1.5em 1.5em 0; }

    &_items {
      margin: 0 1.5em 1.5em;
      flex-grow: 3;

      &_none {
        height: 100%;
        background: #FAFAFA;
        border: 1px solid #EBEBEB;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        &_desc, a {
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          color: #B2B2B2; } } } }

  &__map {
    width: 30%;
    height: 100%; } }

