.carrierAct {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; }

  &__content {
    margin: 1.5em; }

  div.InfoCard {
    h1 {
      margin-bottom: 1em !important;
      font-size: 18px; } }

  .MuiInputBase-root .MuiInputAdornment-positionEnd button {
    margin-right: 0; }

  .MuiGrid-item {
    width: 100%; } }
