.InfoField {
  label {
    display: block;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #111111;
    margin-top: 0;
    margin-bottom: 8px;
    white-space: nowrap; }

  &_field {
    height: 40px;
    border: 1px solid #E2E8F0;
    border-radius: 4px;
    color: #111111 !important;
    background: #EBEBEB;

    input {
      margin: 8px; }

    &_green {
      background: #6dcd45;
      border-color: #6dcd45;
      color: #ffffff !important;
      text-align: center !important; }

    &_shortage {
      background: #FAD2CF;
      border-color: #F6B3AD;
      text-align: center !important; }

    &_surplus {
      background: #FFDC99;
      border-color: #FFC75C;
      text-align: center !important; }

    &_green input {
      text-align: center; } }

  &_files {
    background: #FFFFFF;
    border: 1px solid #E2E8F0 !important;
    border-radius: 4px !important;
    text-transform: none !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #94A3B8 !important; } }

