.descBid {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  &__body {
    flex-grow: 3;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;

    &_content {
      display: flex;
      flex-direction: column;
      gap: 0.5em;
      margin: 1.5em;

      div.InfoCard {
        margin: 0;

        &_body {
          &_items {
            &_infoItem {
              &_label {
                width: 40%; } } } } } } } }
