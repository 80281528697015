.bidCard {
  border: 1px solid #E2E8F0;
  border-radius: 4px;
  min-width: fit-content;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  font-family: 'Inter';
  font-style: normal;

  $marginRow: 1em;

  &__header {
    background: #ffffff;
    height: 100%;
    overflow: hidden;

    &_panel, &_description {
      margin: $marginRow; }

    &_panel {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &_title {
        min-width: 10em; }

      &_control {
        display: flex;
        justify-content: flex-end;
        min-width: fit-content; } }

    &_description {
      display: flex;
      justify-content: flex-start;
      min-width: fit-content; } }

  &__footer {
    background: #F7F7F7;
    height: 100%;
    border-top: 1px solid #DFE7F2;

    &_freeSpace {
      margin: $marginRow;

      &_title {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #b2b2b2; }

      &_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px 0 0;

        &_items {
          display: flex;
          justify-content: flex-start;
          width: 100%;
          overflow-x: hidden;

          div.fullFilled {
            .infoCell_label {
              margin-top: -5px;
              color: #B2B2B2 !important; } }

          div.infoCell {
            &_label {
              font-weight: 600;
              color: #111111; } }

          div.infoCell:last-child .infoCell_separator {
            display: none; } }

        &_counter {
          width: 50px;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          text-align: center;
          margin-right: 40px; } } } } }
