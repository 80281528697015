.bidShippingRespondModal {
  min-width: 850px;

  &_days {

    &_day {

      &_desc {
        display: flex;
        justify-content: space-between;
        align-items: baseline;

        border-bottom: 1px solid #EBEBEB;
        padding-bottom: 1em;
        margin-bottom: 1em; }

      &_truckRunItems {
        &_item:only-child,
        &_item:last-child {
          border: none;
          padding-bottom: 1em; }

        &_item {
          border-bottom: 1px solid #EBEBEB;
          padding-bottom: 1em;
          margin-bottom: 1em; } }

      &_shipping {
        &_items {
          display: flex;
          flex-direction: column;
          gap: 8px; } } } } }
