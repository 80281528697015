.costRange {
  &__slider {
    &_track {
      margin: 0 10px; }
    &_marks {
      display: flex;
      justify-content: space-between;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      &_left {}
      &_right {} } } }
