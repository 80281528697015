.carrierBids {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &__control {
    flex-grow: 1;

    &_filters {
      background: #fff;
      border-bottom: 1px solid #E2E8F0;
      display: flex;
      justify-content: space-between;
      padding: 1em 1.5em 0;
      max-height: fit-content;

      &_left {
        flex-grow: 2; }

      &_right {
        flex-grow: 1;
        text-align: right; } } }

  &__body {
    display: flex;
    flex-direction: row;
    flex-grow: 3;
    overflow: hidden;

    &_content {
      min-width: 50%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: scroll;

      &_none {
        height: 100%;
        display: flex; } }

    &_map {
      width: 50%;
      overflow: hidden; } } }
