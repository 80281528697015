.infoCell {
  position: relative;
  margin: 0 12px 0;
  min-width: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.4em;
  justify-content: center;
  min-height: 100%;

  &_separator {
    position: absolute;
    top: 10px;
    right: -12px;
    width: 1px;
    height: 24px;
    background: #E2E8F0; }

  &_value {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    min-width: fit-content; }

  &_label {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #94A3B8;
    min-width: fit-content; } }

.infoCell:first-child {
  margin-left: 0; }

.infoCell:last-child .infoCell_separator {
    display: none; }

.infoCell:only-child .infoCell_separator {
    display: none; }

.infoCell:only-child {
  margin: 0; }
