.adminParty {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__body {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden; }

  &__content {
    margin: 1.5em; } }


