.navigatePanel {
  background: #fff;
  border-bottom: 1px solid #E2E8F0;
  display: flex;
  flex-direction: column;

  $marginDef: 1.5em;

  &__find {
    height: 48px;
    padding: 0 $marginDef;
    border-bottom: 1px solid #EBEBEB;
    display: flex;
    justify-content: center; }

  &__row {
    margin: 24px $marginDef 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin: 9px 0 0;
      font-weight: 700;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: -0.04em;
      color: #111111; }

    &_left {
      // width: 50%
      flex-grow: 2; }

    &_middle {
 }      // width: 50%

    &_right {
      // width: 50%
      flex-grow: 1;
      text-align: right; } }

  &__tabs {
    margin: 0 $marginDef;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;

    div.main_tabs {
      flex-grow: 3; }

    &_control {
      display: flex;
      align-items: center; } } }
