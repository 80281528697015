.bidsFilters {
  button {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    height: 40px;
    // min-width: 32px
    border-radius: 4px;
    text-decoration: none !important;
    text-transform: none !important; } }
