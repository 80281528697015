.bidsMapBalloon {
  min-width: 300px;
  height: 100%;
  font-family: 'Inter';
  font-style: normal;
  color: #0F172A;
  margin: 1em;

  &_row {
    display: flex;
    margin-bottom: 17px; }

  &_item {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    width: 50%;

    &_label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #94A3B8; } } }

// Этот стиль необходим чтобы скрыть кнопку редактирования маршрута на карте.
// К сожалению в библиотеке не реализован функционал сокрытия данной кнопки
.leaflet-control-container .leaflet-routing-container-hide {
  display: none !important; }

.leaflet-popup-content {
  width: fit-content !important;
  // НЕОБХОДИМ!!! Иначе добавляются ненужные отступы которые портят верстку
  p {
    margin: 0 !important; } }

.leaflet-popup-close-button {
  margin: 2em;

  span {
    font-size: 24px; } }

.leaflet-popup-content-wrapper {
  border-radius: 4px !important; }
