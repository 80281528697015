.users {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;

  &__body {
    flex-grow: 3;
    overflow-y: none;
    overflow-x: auto;

    &_table {
      margin: 1.5em; } } }
