.none {
  flex-grow: 3;
  background: #FAFAFA;
  border: 1px solid #EBEBEB;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;

  &_desc, a {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #B2B2B2; } }
