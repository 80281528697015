.carrierTripRow {
  display: flex;
  justify-content: space-between;
  margin: 12px 0;

  background: #FAFAFA;
  border-radius: 4px;
  padding: 11px 16px;
  overflow: hidden;

  cursor: pointer;

  &__item {
    flex-grow: 2;
    width: 100%; } }

